<template>
  <b-input-group class="product-search-container">
    <template #prepend>
      <search-icon class="search-icon" />
    </template>
    <b-form-input
      v-model="query"
      class="product-search-input"
      placeholder="Search for products"
      @input="debounceSearch"
      @focus="showResults()"
      @blur="hideResults()"
    />

    <div v-if="navigatingToProduct" class="search-result-selected">
      <ct-centered-spinner>
        Loading product...
      </ct-centered-spinner>
    </div>
    <div
      v-else-if="resultsVisible && (typing || lastSearchedTerm)"
      class="search-results"
      @mousedown.prevent
    >
      <ct-centered-spinner v-if="!resultsLoaded" />
      <template v-else>
        <p
          v-if="searchResults?.length < 1"
          class="text-center mb-0 no-results-found"
        >
          No Results found
        </p>
        <template v-else>
          <div
            v-for="result in searchResults"
            :key="result.id"
            class="search-result-name"
            @click.prevent="navigateToProduct(result)"
          >
            {{ result.category.display_name }}
          </div>
        </template>
      </template>
    </div>
  </b-input-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { getProductRoute } from '@/components/HireUs/helper'

export default {
  name: 'ProductSearch',

  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    SearchIcon: () => import('@images/ui/search-icon.svg'),
  },

  mixins: [makeToastMixin],

  data() {
    return {
      resultsVisible: false,
      typing: false,
      debounce: null,
      resultsLoaded: false,
      query: '',
      searchInProgress: false,
      searchResults: [],
      navigatingToProduct: false,
      lastSearchedTerm: null,
    }
  },

  computed: {
    ...mapGetters('companies', [
      'currentCompany',
    ]),
    ...mapGetters('products', [
      'products',
    ]),
  },

  watch: {
    lastSearchedTerm() {
      if (this.lastSearchedTerm) this.search()
    },
  },

  methods: {
    ...mapActions('products', [
      'searchProductCategories',
      'getProducts',
    ]),
    showResults() {
      this.resultsVisible = true
    },
    hideResults() {
      setTimeout(() => {
        this.resultsVisible = false
      }, 100)
    },
    debounceSearch() {
      this.resultsLoaded = false
      this.lastSearchedTerm = null
      this.typing = true

      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.typing = false
        this.lastSearchedTerm = this.query
      }, 300)
    },
    async search() {
      if (this.lastSearchedTerm.length < 2) {
        this.warningToast('Error',`Search term is too short (minimum is 2 characters)`)
        this.searchResults = []
        this.resultsLoaded = true
        return
      }
      this.resultsLoaded = false
      this.searchInProgress = true

      const results = await this.searchProductCategories({
        companyId: this.currentCompany.id,
        categoryName: this.lastSearchedTerm,
        limit: 10,
        matchType: 'partial',
      })

      this.$root.$emit('log-interaction', {
        type: 'button',
        name: 'search-for-product',
        additionalParams: { searchTerm: this.lastSearchedTerm, category: 'hire-us-search' },
      })

      if (results.error) {
        this.searchResults = []
        this.searchInProgress = false
        this.warningToast('Error',`Error searching for ${this.lastSearchedTerm}, please try again later.`)
        return
      } else if (!results?.length) {
        this.searchResults = []
        this.$root.$emit('log-interaction', {
          type: 'view',
          name: 'no-product-search-results',
          additionalParams: { searchTerm: this.lastSearchedTerm, category: 'hire-us-search' },
        })
      } else {
        this.searchResults = results
      }
      this.searchInProgress = false
      this.resultsLoaded = true
    },

    async navigateToProduct(searchResult) {
      this.navigatingToProduct = true
      const category = searchResult.category

      const hasRequiredFields = category?.id && category?.external_product_bucket_id

      if (!hasRequiredFields) {
        this.warningToast('No Products/Categories',`No products/categories to show for category "${category.name}"`)
        this.navigatingToProduct = false
        return
      }

      await this.loadProducts(category)

      // Filter out any bundles that do not have any products available
      const filteredProducts = this.products.filter(p =>
        !(p?.kind === 'product_bundle' && (!p.products || p.products.length === 0))
      )

      if (filteredProducts.length >= 1 ) {
        await this.route(category, this.products)
      } else {
        this.warningToast('No Products/Categories',`No products/categories to show for category "${category.name}"`)
        this.navigatingToProduct = false
      }
    },
    async loadProducts(category) {
      const params = {
        companyId: this.currentCompany.id,
        bucketId: category.external_product_bucket_id,
        categoryId: category.id,
      }
      await this.getProducts(params)
    },
    async route(category, products) {
      const categoryParams = {
        bucketId: category?.external_product_bucket_id,
        categoryId: category.id,
        name: category.name,
      }

      this.$root.$emit('log-interaction', {
        type: 'button-redirect',
        name: 'search-result-selected',
        subName: category.name,
        additionalParams: { searchTerm: this.query, category: 'hire-us-search' },
      })
      const route = await getProductRoute(products, categoryParams, this.currentCompany.id)
      await this.$router.push(route)
    },
  },
}
</script>
<style lang="scss" scoped>
.product-search-container {
  position: relative;

  input {
    height: 50px !important;
    margin-bottom: 0 !important;
    min-height: 50px !important;
  }
}

.search-icon {
   position: absolute;
   top: 50%;
   left: 10px;
   transform: translateY(-50%);
   z-index: 1000;
 }

.product-search-input {
  border-radius: 8px !important;
  padding-left: 40px;
}

.search-results {
  opacity: 100;
}

.search-results, .search-result-selected {
  position: absolute;
  top: 3rem;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  margin-top: 0.313rem;
  max-height: 225px;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
  display: block;
  transition: opacity 0.3s ease;
}
.search-results div {
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
}

.no-results-found, .search-results div {
  padding: 0.625rem;
}

.search-result-name {
  border-bottom: 1px solid #d4d4d4;
  width: 100%;
}

.search-result-selected {
  height: 6.25rem;
}
</style>
