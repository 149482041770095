var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-input-group",
    {
      staticClass: "product-search-container",
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function () {
            return [_c("search-icon", { staticClass: "search-icon" })]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("b-form-input", {
        staticClass: "product-search-input",
        attrs: { placeholder: "Search for products" },
        on: {
          input: _vm.debounceSearch,
          focus: function ($event) {
            return _vm.showResults()
          },
          blur: function ($event) {
            return _vm.hideResults()
          },
        },
        model: {
          value: _vm.query,
          callback: function ($$v) {
            _vm.query = $$v
          },
          expression: "query",
        },
      }),
      _vm.navigatingToProduct
        ? _c(
            "div",
            { staticClass: "search-result-selected" },
            [
              _c("ct-centered-spinner", [
                _vm._v("\n      Loading product...\n    "),
              ]),
            ],
            1
          )
        : _vm.resultsVisible && (_vm.typing || _vm.lastSearchedTerm)
        ? _c(
            "div",
            {
              staticClass: "search-results",
              on: {
                mousedown: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              !_vm.resultsLoaded
                ? _c("ct-centered-spinner")
                : [
                    _vm.searchResults?.length < 1
                      ? _c(
                          "p",
                          { staticClass: "text-center mb-0 no-results-found" },
                          [_vm._v("\n        No Results found\n      ")]
                        )
                      : _vm._l(_vm.searchResults, function (result) {
                          return _c(
                            "div",
                            {
                              key: result.id,
                              staticClass: "search-result-name",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.navigateToProduct(result)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(result.category.display_name) +
                                  "\n        "
                              ),
                            ]
                          )
                        }),
                  ],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }